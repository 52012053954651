<!--
 * @Author: your name
 * @Date: 2021-01-15 17:24:58
 * @LastEditTime: 2021-03-17 18:07:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\postfour\index.vue
-->
<template>
  <div class="theallmsg">
    <navigation :msg="msg"></navigation>
    <div class="mainclass">
      <div class="gwclass">
        <div>自定义<span>产品包</span></div>
        <div>源自萝卜猎手雇前背景调查中心</div>
      </div>
      <div class="jieclas">
        <div></div>
        <div>您可以对产品进行自由组合，从而获得更多、更全面的报告</div>
      </div>
      <div class="whyclass">
        <div>为什么要使用萝卜猎手？</div>
        <div class="whymsg">
          <div></div>
          <div>
            <div>能够通过自由的产品组合，获得</div>
            <div>您所要调查的报告结果</div>
          </div>
        </div>
      </div>
      <div class="bx">背调项目</div>
      <div
        v-for="(item, index) in alltao.set_id"
        v-bind:key="index"
        class="taoclass"
      >
        <div>{{ item.name }}</div>
        <div>¥ {{ item.price }}元</div>
      </div>
    </div>
    <div class="paymoneyclass">
      <div @click="clickkefu" class="kecl">
        <div><img src="../../assets/kefu3.png" /></div>
        <div>联系客服</div>
      </div>
      <div class="mcl1">
        <div>按照所选择</div>
        <div>产品总计</div>
      </div>
      <div><button @click="fqbd" :style="ishavemsg==true?'':'background:#939393'" class="btp">发起背调</button></div>
    </div>
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import kefu from "../../components/kefu/index"
export default {
  components: {
    kefu
  },
  data() {
    return {
      msg: "自定义产品包",
      custao: [],
      alltao: {
        set_id: "",
      },
      ishavemsg: false,
    };
  },
  methods: {
    clickkefu() {
      this.$refs.kefuid.showmeth();
    },
    fqbd() {
      if (!this.ishavemsg) return;
      this.$router.push({
        name: "toback",
        params: {
          taomsg: this.alltao,
          id: "4",
          isqrcode: this.$route.query.isqrcode,
        },
      });
    },
  },
  created() {
    this.$http
      .post("/firm/v1/background/commodity", {
        reqType: "Background",
        is_once: 2,
      })
      .then((res) => {
        this.ishavemsg = true;
        let reses = JSON.parse(res.data);
        
        let arraymsg = reses.data.list.data;
        for (let i = 0; i < arraymsg.length; i++) {
          let setid = arraymsg[i].set_id;
          for (let j = 0; j < setid.length; j++) {
            this.custao.push(setid[j]);
          }
        }
        this.alltao.set_id = this.custao;
      });
  },
};
</script>
<style scoped>
.mcl1 > div:nth-child(1) {
  font-size: 0.24rem;
  line-height: 0.33rem;
}
.mcl1 > div:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.33rem;
}
.mcl1 > div {
  text-align: center;
}
.mcl1 {
  font-size: 0.38rem;
  line-height: 0.53rem;
  color: #f55814;
  font-weight: bold;
}
.kecl > div:first-child {
  text-align: center;
}
.kecl > div:nth-child(2) {
  text-align: center;
  color: #003aff;
  margin-top: 0.03rem;
}
.kecl img {
  width: 0.49rem;
  height: 0.48rem;
}
.btp {
  background: linear-gradient(to right, #f55613, #fc9e46);
  border: none;
  color: #ffffff;
  padding: 0.24rem 1.43rem;
  border-radius: 0.2rem;
  font-size: .3rem;
  line-height: .42rem;
}
.paymoneyclass {
  position: fixed;
  padding: 2% 3%;
  width: 94%;
  background-color: #ffffff;
  bottom: 0;
  box-shadow: 0 -0.3rem 0.3rem #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.taoclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #6f6f6f;
  padding: 0.25rem 0;
  border-bottom-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bx {
  padding-bottom: 0.14rem;
  font-size: 0.34rem;
  line-height: 0.48rem;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #ff6600;
  display: inline-block;
  margin-bottom: 0.31rem;
}
.whymsg > div:first-child {
  width: 0.13rem;
  height: 0.13rem;
  border-radius: 50%;
  border: 0.1rem solid #ff6a29;
  margin-right: 0.18rem;
}
.whymsg {
  display: flex;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #6f6f6f;
  margin-top: 0.14rem;
  margin-bottom: 0.08rem;
}
.whyclass > div:first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #222222;
  font-weight: bold;
}
.whyclass {
  background-image: url("../../assets/postfourke.png");
  background-size: 100% 100%;
  height: 2.07rem;
  margin-top: 0.55rem;
  margin-bottom: 0.61rem;
  padding: 0.41rem 0.52rem;
}
.mainclass {
  margin: 0.4rem 0.2rem;
  margin-bottom: 0.17rem;
  padding-bottom: 2rem;
}
.jieclas > div:first-child {
  min-width: 0.12rem;
  max-height: 0.12rem;
  border-radius: 50%;
  background-color: #939393;
  margin-right: 0.16rem;
  margin-top: 0.1rem;
}
.jieclas {
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: #939393;
  display: flex;
}
.theallmsg {
  background-color: #ffffff;
}
.gwclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.17rem;
}
.gwclass > div:nth-child(2) {
  background-color: #f55814;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  overflow: hidden;
  padding: 0.06rem 0.19rem;
  color: #ffffff;
}
.gwclass > div:first-child {
  font-size: 0.46rem;
  line-height: 0.65rem;
  color: #222222;
  font-weight: bold;
}
.gwclass span {
  color: #f55814;
}
</style>